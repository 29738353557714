<template>
  <SuiModal v-model="dialog">
    <SuiModalContent style="height: 530px; display: flex; flex-direction:column">
      <a>
        <i
          @click="close()"
          style="text-decoration:none !important; cursor: pointer;font-size: 25px;
          position: sticky; left: 105%; top: 10px; margin-right: -5px; margin-top: -5px;"
          class="times icon general-blue-color">
        </i>
      </a>
      <SuiModalDescription style="width: 100%; padding-right:15px; padding-left:15px; display: flex; flex-direction: column;">
        <h1 v-if="isNewBulletin" style="margin-top: -15px">{{  $t('board.create_announcement') }}</h1>
        <h1 v-else style="margin-top: -15px">{{edit_data.values.title}}</h1>

        <form
          class="ui form fields"
          :class="{'blue medium double loading':isLoadingSave}"
          style="width: 100%; height: 80%; display: flex"
        >
          <div class="left" style="width: 50%; height: 100%">

            <div class="title_field" style="width: 100%; justify-content: space-between; margin-bottom: 8px">
              <label style="font-size: 16px; color: #323232;">{{  $t('board.headers.title') }}</label>
              <div class="ui input" style="width: 85%">
                <input  type="text" maxlength="25" :placeholder="$t('board.announcement_title')" v-model="titleOfBulletin">
              </div>
            </div>

            <div class="link_field" style="width: 100%; display: flex; justify-content: space-between; margin-bottom: 8px">
              <label style="font-size: 16px; align-self: center; color: #323232">{{  $t('board.headers.link') }}</label>
              <div class="ui input" style="width: 85%">
                <input type="text" :placeholder="$t('board.headers.link')" v-model="linkOfBulletin">
              </div>
            </div>

            <div class="description_field" style="width: 100%">
                <div class="ui input" style="width: 100%">
                  <textarea
                    style="border: 1px solid rgba(34,36,38,.15); resize:none; width: 100%"
                   maxlength="200" :placeholder="$t('board.announcement_description')" rows="4" v-model="descriptionOfBulletin"/>
                </div>
            </div>

              <div
                class="coverPhoto_field"
                style="display: flex; flex-direction:column; height: 50px; margin-top: 10px">
                <label style="align-self: flex-start;font-size: 16px;color: #323232;margin-bottom: 2px">{{  $t('board.headers.cover_image') }}</label>
                <FileUploader
                  id='coverPicture'
                  :maxNumFiles="1"
                  :maxFileSizeInMB="10"
                  :allowedFileTypes="['jpg', 'png', 'jpeg']"
                  :sizeOfButton="{'height': '33px', 'width': '110px'}"
                  :modelValue="coverPictureOfBulletin"
                  @update-modelValue="captureCoverPicture"
                />
              </div>

              <div
                class="attachments_field"
                style="display: flex;  flex-direction:column; height: 50px; margin-top: 15px">
                <label style="margin-bottom: 2px;font-size: 16px;color: #323232;">{{  $t('board.attachements') }}</label>
                <FileUploader
                  id='attachments'
                  :maxNumFiles="3"
                  :maxFileSizeInMB="10"
                  :allowedFileTypes="['pdf', 'jpg', 'png', 'jpeg']"
                  :sizeOfButton="{'height': '33px', 'width': '110px'}"
                  :maxLengthPerName="11"
                  :modelValue="filesOfBulletin"
                  @update-modelValue="captureFiles"
                />
              </div>

          </div>
          <div class="right" style="width: 50%; height: 100%; display: flex; align-items: center">

            <div
              class="right-content"
              style="height: 50%; width: 100%; padding-left: 40px"
              :style="isNewBulletin ? 'margin-top: -35px' : 'margin-top: -75px'">

              <div v-if="!isNewBulletin" class="creationDate">
                <div class="date" style="width: 55%; display: flex; justify-content: space-between">
                  <label style="font-size: 16px; color: #323232">{{  $t('board.created_at') }}</label>
                  <span style="padding-right: 35px">{{form.createdAt}}</span>
                </div>
                <div class="hour" style="width: 45%; display: flex; justify-content: space-between; align-items: center">
                  <label style="font-size: 16px; color: #323232; padding-left: 10px">{{  $t('board.time') }}</label>
                  <span style="padding-right: 30px">{{form.creationHour}}</span>
                </div>
              </div>

              <div class="publicationDate">
                <div
                  class="date"
                  style="width: 55%; display: flex; align-items: center">
                  <label style="font-size: 16px; color: #323232; margin-right: 10px">{{  $t('board.headers.publication') }}</label>
                  <Datepicker
                    style="
                    --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
                    --vdp-selected-bg-color: #436f98; text-align: center; max-width: 110px"
                    inputFormat='dd/MM/yyyy'
                    :lowerLimit="nowDate"
                    :locale="getLanguage()"
                    :typeable="false"
                    v-model="form.activatedAt"
                  />
                </div>
                <div class="hour" style="width: 45%; display: flex; justify-content: space-between; align-items: center">
                  <label style="font-size: 16px; color: #323232; padding-left: 10px; margin-right: 5px">{{  $t('board.time') }}</label>
                  <div class="mb-2" style="display: flex; flex-direction: row;
                   justify-content: flex-end !important; margin-top: 10px">
                    <SuiDropdown
                      style="min-width: 20px !important; max-width: 20px !important"
                      selection
                      v-model="form.activationHour"
                      placeholder="00"
                      :options="hours"
                    />
                    <SuiDropdown
                      style="min-width: 20px !important; max-width: 20px !important"
                      selection
                      v-model="form.activationHourPeriod"
                      placeholder="AM"
                      :options="['AM', 'PM']"
                    />
                  </div>
                </div>
              </div>

              <div class="expirationDate">
                <div class="date" style="width: 55%; display: flex; align-items: center">
                  <label style="font-size: 16px; color: #323232; margin-right: 17px">{{  $t('board.expiration') }}</label>
                  <Datepicker
                    style="
                    --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
                    --vdp-selected-bg-color: #436f98; text-align: center; max-width: 110px"
                    inputFormat='dd/MM/yyyy'
                    :lowerLimit="nowDate"
                    :locale="getLanguage()"
                    :typeable="false"
                    v-model="form.expiresAt"
                  />
                </div>
                <div class="hour" style="width: 45%; display: flex; justify-content: space-between; align-items: center">
                  <label style="font-size: 16px; color: #323232; padding-left: 10px; margin-right: 5px">{{  $t('board.time') }}</label>
                  <div class="mb-2" style="display: flex; flex-direction: row;  justify-content: flex-end !important">
                    <SuiDropdown
                      style="min-width: 20px !important; max-width: 20px !important"
                      selection
                      v-model="form.expiresHour"
                      placeholder="00"
                      :options="hours"
                    />
                    <SuiDropdown
                      style="min-width: 20px !important; max-width: 20px !important"
                      selection
                      v-model="form.expiresHourPeriod"
                      placeholder="AM"
                      :options="['AM', 'PM']"
                    />
                  </div>
                </div>
              </div>

              <div
                class="isImportant_and_isActive"
                style="width: 100%; display:flex; align-items: center"
              >
                <div style="
                  display: flex; flex-direction: column; justify-content: center;
                  width: 50%; height: 100%; justify-content: space-between; padding-top: 10px;
                  align-items: center; padding-left: -35px"
                >
                  <label
                    style="margin-bottom: 10px;color: #323232;">
                  {{  $t('board.headers.active') }}
                  </label>
                  <div
                  class="ui toggle checkbox"
                  style="transform: scale(1.3)"
                  >
                    <input type="checkbox" :v-model="form.isActive" :checked="form.isActive" @change="changeActivation()">
                    <label style="height: 0px; width:0px"></label>
                  </div>
                  <span style="color: #323232;">{{ form.isActive ?  $t('yes')  :  $t('no') }}</span>
                </div>

                <div
                  style="display: flex; flex-direction: column; justify-content: center;
                  width: 50%; height: 100%; justify-content: space-between; padding-top: 10px;
                  align-items: center; padding-left: -45px">
                  <label style="margin-bottom: 8px;color: #323232;">{{  $t('board.headers.highlighted') }}</label>
                  <i
                    class="thumbtack icon"
                    style="font-size: 20px; cursor: pointer"
                    :class="classObjectIsImportant(form.isImportant)"
                    @click="changeImportance()"></i>
                  <span style="color: #323232;">{{ form.isImportant ? $t('yes')  :  $t('no') }}</span>
                </div>
              </div>

            </div>

          </div>

        </form>

        <div style="margin-top: 360px ;align-self:center; position: absolute" v-if="(v$.form.title.$error || v$.form.expiresHourPeriod.$error || v$.form.expiresAt.$error || v$.form.expiresHour.$error)">
          <span class="text-danger"  v-if="v$.form.title.$error">
            {{v$.form.title.$errors[0].$message }}
          </span>
          <span v-else-if="v$.form.expiresAt.$error" class="text-danger">
            {{ v$.form.expiresAt.$errors[0].$message}}
          </span>
          <span v-else-if="v$.form.expiresHour.$error" class="text-danger">
            {{ v$.form.expiresHour.$errors[0].$message}}
          </span>
          <span v-else-if="v$.form.expiresHourPeriod.$error" class="text-danger">
            {{v$.form.expiresHourPeriod.$errors[0].$message }}
          </span>
          <!-- <span v-else-if="error" class="text-danger">{{$t('errors.emailTaken')}}</span> -->
        </div>

        <div class="btns" :class="{'disabled':isLoadingSave}">
          <Button v-if="!isNewBulletin"
            :appearance="classBtnObject"
            class="delete"
            :text="$t('board.delete')"
            color="third-color"
            @clicked="deleteBulletin"
            :class="{'disabled':isLoadingSave}"
          />
          <Button v-if="!isNewBulletin"
            :appearance="classBtnObject"
            class="save"
            :text="$t('board.save')"
            color="primary"
            @clicked="saveChanges"
            :class="{'disabled':isLoadingSave}"
          />
          <Button v-else
            class="d-inline"
            :appearance="classBtnObject"
            :text="$t('board.create')"
            color="primary"
            @clicked="createBulletin"
            :class="{'disabled':isLoadingSave}"
          />
        </div>
      </SuiModalDescription>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
// SuiDropdown
import { SuiModal, SuiModalContent, SuiModalDescription, SuiDropdown } from 'vue-fomantic-ui'
import Button from '@/components/form/Button'
import FileUploader from '@/components/form/FileUploader'
import {
  createBulletin,
  deleteBulletin as deleteBltn,
  updateBulletin
} from '@/api/bulletinBoard'
import Datepicker from 'vue3-datepicker'
import { mapActions, mapGetters } from 'vuex'
import { es, en } from 'date-fns/locale'
import i18n from '@/plugins/i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators/dist/raw.esm'

export default {
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    edit_data: {
      type: Object,
      required: false
    },
    isFullOfImportant: {
      type: Boolean,
      required: true
    }
  },
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Button,
    FileUploader,
    SuiDropdown,
    Datepicker
    // SuiDropdown

  },
  emits: ['close'],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      form: {
        title: '',
        coverPhoto: [],
        description: '',
        createdAt: '',
        creationHour: null,
        attachments: [],
        isImportant: false,
        isActive: false,
        link: '',
        expiresAt: null,
        expiresHour: null,
        expiresHourPeriod: null,
        activatedAt: null,
        activationHour: null,
        activationHourPeriod: null
      },
      isLoadingSave: false,
      hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      nowDate: new Date()
    }
  },

  validations () {
    return {
      form: {
        title: {
          required: helpers.withMessage(this.$t('errors.board.must_have_title'), required)
        },
        expiresAt: {
          required: helpers.withMessage(this.$t('errors.board.expirationDate'), required)
        },
        expiresHour: {
          required: helpers.withMessage(this.$t('errors.board.expirationTime'), required)
        },
        expiresHourPeriod: {
          required: helpers.withMessage(this.$t('errors.board.chooseAMPM'), required)
        }
      }
    }
  },

  updated () {
    this.clearForm()
    if (!this.isNewBulletin) {
      // console.log('Incoming values of bulletin already created --->', this.edit_data.values)
      this.form.title = this.edit_data.values.title
      this.coverPictureOfBulletin = [this.edit_data.values.coverPictureURL]
      this.form.description = this.edit_data.values.Description
      // setting Dates

      // Creation
      // const creationDate = new Date(this.edit_data.values.createdAt)
      const creationDate = new Date(this.edit_data.values.createdAt.replace('Z', ''))
      let creationDay = creationDate.getDate()
      creationDay = creationDay < 10 ? `0${creationDay}` : creationDay
      let creationMonth = creationDate.getMonth() + 1
      creationMonth = creationMonth < 10 ? `0${creationMonth}` : creationMonth
      this.form.createdAt = `${creationDay}/${creationMonth}/${creationDate.getFullYear()}`
      let creationHour = creationDate.getHours()
      const creationPeriod = creationHour > 12 ? 'PM' : 'AM'
      creationHour = creationHour > 12 ? creationHour - 12 : creationHour
      creationHour = creationHour < 10 ? `0${creationHour}` : `${creationHour}`
      let creationMinutes = creationDate.getMinutes()
      creationMinutes = creationMinutes < 10 ? `0${creationMinutes}` : `${creationMinutes}`
      this.form.creationHour = `${creationHour}:${creationMinutes} ${creationPeriod}`

      // Activation
      if (this.edit_data.values.activatedAt) {
        // const activationDate = new Date(this.edit_data.values.activatedAt)
        const activationDate = new Date(this.edit_data.values.activatedAt.replace('Z', ''))
        // --------------------------------------------------------------------
        // ESTO DEBE QUITARSE EN EL SIGUIENTE AJUSTE DE HORAS Y FECHAS
        // activationDate.setHours(activationDate.getHours() - 1)
        // --------------------------------------------------------------------
        this.form.activatedAt = activationDate
        let activationHour = activationDate.getHours()
        this.form.activationHourPeriod = activationHour >= 12 ? 'PM' : 'AM'
        activationHour = activationHour > 12 ? activationHour - 12 : (activationHour === 0 ? 12 : activationHour)
        activationHour = activationHour < 10 ? `0${activationHour}` : `${activationHour}`
        this.form.activationHour = `${activationHour}`
      }

      // Expiration
      if (this.edit_data.values.expiresAt) {
        // const expirationDate = new Date(this.edit_data.values.expiresAt)
        const expirationDate = new Date(this.edit_data.values.expiresAt.replace('Z', ''))
        // --------------------------------------------------------------------
        // ESTO DEBE QUITARSE EN EL SIGUIENTE AJUSTE DE HORAS Y FECHAS
        // expirationDate.setHours(expirationDate.getHours() - 1)
        // --------------------------------------------------------------------
        this.form.expiresAt = expirationDate
        let expirationHour = expirationDate.getHours()
        this.form.expiresHourPeriod = expirationHour >= 12 ? 'PM' : 'AM'
        expirationHour = expirationHour > 12 ? expirationHour - 12 : (expirationHour === 0 ? 12 : expirationHour)
        expirationHour = expirationHour < 10 ? `0${expirationHour}` : `${expirationHour}`
        this.form.expiresHour = `${expirationHour}`
      }

      this.filesOfBulletin = [...this.edit_data.values.files]
      this.form.link = this.edit_data.values.link
      this.form.isImportant = this.edit_data.values.important
      this.form.isActive = this.edit_data.values.active
    } else {
      this.form.activatedAt = this.nowDate
      let nowHour = this.nowDate.getHours()
      nowHour = nowHour > 12 ? nowHour - 12 : (nowHour === 0 ? 12 : nowHour)
      nowHour = nowHour < 10 ? `0${nowHour}` : `${nowHour}`
      this.form.activationHour = `${nowHour}`
      this.form.activationHourPeriod = this.nowDate.getHours() >= 12 ? 'PM' : 'AM'
    }
  },
  computed: {

    ...mapGetters('bulletinBoard', [
      'allBulletins'
    ]),

    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '7px 2.4rem !important'
      }
    },

    es () {
      return es
    },
    en () {
      return en
    },

    expiresAt () {
      return `${this.form.expiresHour}:00:00 ${this.form.expiresHourPeriod}`
    },

    residential () {
      return this.$store.getters['residential/activeResidential']
    },
    dialog () {
      return this.show
    },
    isNewBulletin () {
      return Object.keys(this.edit_data).length === 0
    },
    linkOfBulletin: {
      get () {
        return this.form.link
      },
      set (newVal) {
        this.form.link = newVal
      }
    },

    titleOfBulletin: {
      get () {
        return this.form.title
      },
      set (newVal) {
        this.form.title = newVal
      }
    },

    descriptionOfBulletin: {
      get () {
        return this.form.description
      },
      set (newVal) {
        this.form.description = newVal
      }
    },

    filesOfBulletin: {
      get () {
        return this.form.attachments
      },
      set (newVal) {
        // console.log('incoming attachments', newVal)
        this.form.attachments = newVal
      }
    },

    coverPictureOfBulletin: {
      get () {
        return this.form.coverPhoto
      },
      set (newVal) {
        // console.log('incoming coverPic', newVal)
        this.form.coverPhoto = newVal
      }
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),

    ...mapActions('bulletinBoard', ['addNewBulletin', 'delete_Bulletin', 'update_Bulletin']),

    changeImportance () {
      if (
        (this.isFullOfImportant && this.form.isActive && !this.edit_data.values.important) ||
        (this.isFullOfImportant && !this.form.isActive)) {
        this.$swal(this.$t('errors.board.maxHighlighted'), '', 'error')
      } else {
        this.form.isImportant = !this.form.isImportant
        if (this.form.isImportant) {
          this.form.isActive = true
        }
      }
    },
    getLanguage () {
      if (i18n.global.locale === 'en') {
        return en
      } else {
        return es
      }
    },
    classObjectIsImportant () {
      return {
        importantThumbtack: this.form.isImportant,
        nonImportantThumbtack: !this.form.isImportant
      }
    },

    changeActivation () {
      this.form.isActive = !this.form.isActive
      if (!this.form.isActive) {
        this.form.isImportant = false
      }
    },

    classObjectFiles () {
      return {
        width: this.isNewBulletin ? '100%' : '60%'
      }
    },

    captureFiles (files) {
      this.filesOfBulletin = files
      // console.log(files)
    },

    captureCoverPicture (coverPic) {
      this.coverPictureOfBulletin = coverPic
      // console.log(coverPic)
    },

    close () {
      this.$emit('close')
      this.clearForm()
      this.v$.$reset()
    },

    clearForm () {
      this.form = {
        title: '',
        coverPhoto: [],
        description: '',
        createdAt: '',
        attachments: [],
        isImportant: false,
        isActive: false,
        link: '',
        expiresAt: null,
        expiresHour: null,
        expiresHourPeriod: '',
        activatedAt: null,
        activationHour: null,
        activationHourPeriod: null
      }
    },

    async createBulletin () {
      this.v$.$validate()
      const formValid = !(this.v$.$error)

      if (formValid) {
        // this.isLoadingSave = true
        this.setLoading(true)

        const data = new FormData()

        data.append('title', this.form.title)

        data.append('residentialId', this.residential.residentialId)

        data.append('body', this.form.description)

        data.append('isImportant', this.form.isImportant)

        data.append('link', this.form.link)

        data.append('expiresAt', this.getCompleteDate(this.form.expiresHour, this.form.expiresHourPeriod, this.form.expiresAt))

        if (this.coverPictureOfBulletin.length) {
          data.append('coverPicture', this.coverPictureOfBulletin[0])
        }
        if (this.filesOfBulletin.length) {
          this.filesOfBulletin.forEach(bulletin => {
            data.append('attachments', bulletin)
          })
        }
        data.append('status', this.form.isActive ? 'ACTIVE' : 'INACTIVE')

        data.append('activatedAt', this.getCompleteDate(this.form.activationHour, this.form.activationHourPeriod, this.form.activatedAt))

        // for (const pair of data.entries()) {
        //   console.log(pair[0], pair[1])
        // }

        createBulletin(data).then((r) => {
          const bulletin = r.data
          // console.log('created bulletin response from server ---->', bulletin)
          this.addNewBulletin({
            id: bulletin.id,
            important: bulletin.isImportant,
            title: bulletin.title,
            coverPictureURL: bulletin.coverPicture,
            Description: bulletin.body,
            files: bulletin.attachment,
            createdAt: bulletin.createdAt,
            activatedAt: bulletin.activatedAt,
            expiresAt: bulletin.expiresAt,
            active: bulletin.status === 'ACTIVE',
            link: bulletin.link,
            likes: 0
          })
          // console.log(r)
          // this.isLoadingSave = false
          this.close()
          this.$swal(this.$t('board.createdSuccess'), '', 'success')
        }).catch(() => {
          // this.isLoadingSave = false
          this.$swal(this.$t('errors.board.creatingError'), '', 'error')
        }).finally(() => {
          this.setLoading(false)
        })
      }
    },

    getCompleteDate (hourStr, period, date) {
      const hourInt = parseInt(hourStr)
      let hourFormatted
      if (period === 'AM') {
        hourFormatted = hourInt === 12 ? 0 : hourInt
      } else {
        hourFormatted = hourInt === 12 ? 12 : 12 + hourInt
      }
      hourFormatted = hourFormatted < 10 ? `0${hourFormatted}` : `${hourFormatted}`
      // next change for hour change (UTC refactoring)
      const completeDateExpiration = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${hourFormatted}:00:00`
      // hot fix (production preventive crash)
      // const completeDateExpiration = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${hourFormatted}:00:00`
      return completeDateExpiration
    },

    async saveChanges () {
      // this.isLoadingSave = true
      this.v$.$validate()
      const formValid = !(this.v$.$error)
      // console.log('SAVING CHANGES OF EXISTING BULLETIN')

      if (formValid) {
        this.setLoading(true)

        const data = new FormData()

        data.append('title', this.form.title)

        data.append('residentialId', `${this.residential.residentialId}`)

        data.append('body', this.form.description)

        data.append('isImportant', this.form.isImportant)

        data.append('link', this.form.link)

        // -- Expiration Date
        // const hourExpInt = parseInt(this.form.expiresHour)
        // let hourExpiration

        // if (hourExpInt === 12 && this.form.expiresHourPeriod === 'AM') {
        //   hourExpiration = 0
        // } else if (hourExpInt === 12 && this.form.expiresHourPeriod === 'PM') {
        //   hourExpiration = 12
        // } else {
        //   hourExpiration = this.form.expiresHourPeriod === 'AM' ? hourExpInt : 12 + hourExpInt
        // }
        // hourExpiration = hourExpiration === 0 ? 0 : hourExpiration - 1
        // hourExpiration = hourExpiration < 10 ? `0${hourExpiration}` : `${hourExpiration}`
        // const completeDateExpiration = `${this.form.expiresAt.getFullYear()}-${this.form.expiresAt.getMonth() + 1}-${this.form.expiresAt.getDate()} ${hourExpiration}:00:00`
        // data.append('expiresAt', completeDateExpiration)
        data.append('expiresAt', this.getCompleteDate(this.form.expiresHour, this.form.expiresHourPeriod, this.form.expiresAt))

        // -- Activation Date
        // const hourActivInt = parseInt(this.form.activationHour)
        // let hourActivation
        // //  = this.form.activationHourPeriod === 'AM' ? hourActiv : 12 + hourActiv
        // if (hourActivInt === 12 && this.form.activationHourPeriod === 'AM') {
        //   hourActivation = 0
        // } else if (hourActivInt === 12 && this.form.activationHourPeriod === 'PM') {
        //   hourActivation = 12
        // } else {
        //   hourActivation = this.form.activationHourPeriod === 'AM' ? hourActivInt : 12 + hourActivInt
        // }
        // hourActivation = hourActivation === 0 ? 0 : hourActivation - 1
        // hourActivation = hourActivation < 10 ? `0${hourActivation}` : `${hourActivation}`
        // const completeDateActivation = `${this.form.activatedAt.getFullYear()}-${this.form.activatedAt.getMonth() + 1}-${this.form.activatedAt.getDate()} ${hourActivation}:00:00`
        // data.append('activatedAt', completeDateActivation)
        data.append('activatedAt', this.getCompleteDate(this.form.activationHour, this.form.activationHourPeriod, this.form.activatedAt))

        // Status
        data.append('status', this.form.isActive ? 'ACTIVE' : 'INACTIVE')

        data.append('coverPicture', this.coverPictureOfBulletin.length ? this.coverPictureOfBulletin[0] : null)
        // if (this.coverPictureOfBulletin.length) {
        // }
        if (this.filesOfBulletin.length) {
          // console.log('FILES OF UPDATING BULLETIN -------->', this.filesOfBulletin)
          this.filesOfBulletin.forEach(file => {
            data.append('attachments', file)
          })
        }

        // for (const pair of data.entries()) {
        //   console.log(pair[0], pair[1])
        // }

        updateBulletin(this.edit_data.id, data).then((r) => {
          const bulletin = r.data
          // console.log('bulletin updated response from server --->', bulletin)
          this.update_Bulletin({
            id: bulletin.id,
            important: bulletin.isImportant,
            title: bulletin.title,
            coverPictureURL: bulletin.coverPicture,
            Description: bulletin.body,
            files: bulletin.attachment,
            createdAt: bulletin.createdAt,
            activatedAt: bulletin.activatedAt,
            expiresAt: bulletin.expiresAt,
            active: bulletin.status === 'ACTIVE',
            link: bulletin.link,
            likes: this.edit_data.values.likes
          })
          // console.log(bulletin)
          // console.log(this.edit_data)
          // this.isLoadingSave = false
          this.close()
          this.$swal(this.$t('board.savedSuccess'), '', 'success')
        }).catch(() => {
          // this.isLoadingSave = false
          this.$swal(this.$t('errors.board.updatingError'), '', 'error')
        }).finally(() => {
          this.setLoading(false)
        })
      }

      // console.log('activationDate', completeDateActivation)
      // console.log('expiresAt', completeDateExpiration)
    },

    async deleteBulletin () {
      this.$swal({
        title: this.$t('board.wantToDeleteAnnouncement'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0'
      }).then((result) => {
        if (result.isConfirmed) {
          // this.isLoadingSave = true
          this.setLoading(true)
          deleteBltn(this.edit_data.id).then(r => {
            this.delete_Bulletin(this.edit_data)
            /* console.log('data deleted')
            console.log(r)
            console.log('data in edit data')
            console.log(this.edit_data) */
            // this.isLoadingSave = false
            this.close()
            this.$swal(this.$t('board.deletedSuccess'), '', 'success')
          }).catch(() => {
            // this.isLoadingSave = false
            this.$swal(this.$t('errors.board.deletingError'), '', 'error')
          }).finally(() => {
            this.setLoading(false)
          })
        }
      })
    }

  }
}
</script>

<style scoped>
  h1 {
      color: #2c3e50 !important;
      font-family: inherit !important;
      }
  .btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  textarea:focus {
    border-color: #85b7d9 !important;
  }
  textarea::placeholder {
    color: rgba(34,36,38,.15)
  }
  textarea:focus::placeholder {
    color: rgb(151, 144, 144);
  }

  .title_field, .coverPhoto_field, .date_field, .description_field {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .description_existing_bulletin {
    margin-top: 30px
  }

    .importantThumbtack, .nonImportantThumbtack {
      color: #808080;
      font-size: 20px;
    }

    .importantThumbtack {
      color: rgb(235, 36, 36);
      /* transform: rotate(-45deg); */
      transform: rotate(0deg);
      transition: ease .3s
    }

    /* .importantThumbtack:hover {
      color: #808080;
      transform: rotate(-45deg)
    } */

    .nonImportantThumbtack {
      color: #808080;
      transform: rotate(-45deg);
      transition: ease .3s
    }

    /* .nonImportantThumbtack:hover {
      color: rgb(235, 36, 36);
      transform: rotate(0);
    } */
    .delete, .save {
      /* height: 40px !important;*/
      text-transform: none !important;
      letter-spacing: normal !important;
      border-radius: 5px !important;
      transition: background-color .5s ease !important;
      border: none !important;
      width: 10rem !important;
      font-family: inherit;
    }
  .delete {
    margin-right: 10px !important;
  }

  .save {
    margin-left: 10px !important;
  }

  .creationDate, .publicationDate, .expirationDate {
    width: 100%;
    display: flex;
    align-items: center
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
}
</style>
