<template>
  <div class="justify-content-start view-container">
    <HeaderResidential />
  </div>
  <div class="justify-content-start w-100 view-container" style="margin-bottom: 50px">
    <div class="create_and_changeSection_btns">
      <Button v-if="!loading"
        :appearance="classObjectCreateBulletin"
        :text="$t('board.new_announcement')"
        color="primary"
        @clicked="openEditModal({})"
      />

      <div class="changeSection">
        <div style="width: 50% !important; display: flex; justify-content: center; align-items: center">
          <Button v-if="!loading"
            :appearance="classObjectSection"
            :text="$t('status.active')"
            :color="isActivesSection ? 'primary' : 'gray'"
            @click="() => {isActivesSection = true; currentPageOfBulletins = 1}"
          />
        </div>
        <div style="width: 50% !important; display: flex; justify-content: center; align-items: center">
          <Button v-if="!loading"
            :appearance="classObjectSection"
            :text="$t('status.inactive')"
            :color="!isActivesSection ? 'primary' : 'gray'"
            @click="() => {isActivesSection = false; currentPageOfBulletins = 1}"
          />
        </div>
      </div>

    </div>

    <div
      style="width: 100%; padding-right: 35px; ; margin-bottom: 100px"
    >
      <table v-if="!loading" class="ui blue unstackable selectable celled table"
       style="cursor: default !important; color: #808080">
        <thead>
        <tr>
          <th v-for="header of boardHeaders" :key="header"
          style="text-align: center; color: #808080"
          :class="{'one wide':(header==='Activo' || header==='Destacado' || header==='')}">
            <div v-if="header === 'Reacciones'">
              <i class="heart icon"></i>
            </div>
            <div v-else>
              {{header}}
            </div>
          </th>
        </tr>
        </thead>
        <tbody v-if="bulletinsExist">
        <tr v-for="bulletin in visualBulletin" :key="bulletin.id" :class="classObjectIsImportantRow(bulletin.values.important)">
          <td v-for="(value, field) in {
            title: bulletin.values.title,
            coverPictureURL: bulletin.values.coverPictureURL,
            Description: bulletin.values.Description,
            link: bulletin.values.link,
            files: bulletin.values.files,
            likes: bulletin.values.likes,
            activatedAt: bulletin.values.activatedAt,
            edit: '',
            active: bulletin.values.active,
            important: bulletin.values.important
          }"
            :key="field"
            :class="{'CoverPicture':field === 'coverPictureURL' && value}"
            @click="(field === 'Description' || field === 'title') ? openEditModal(bulletin):''"
            :style="(field === 'Description') ? 'max-width: 230px' : ''"
          >
            <div v-if="field === 'important'" class="elementInTD">
              <i class="thumbtack icon" :class="classObjectIsImportant(value)" @click="changeImportance(bulletin, value)"></i>
            </div>
            <div v-else-if="field === 'coverPictureURL' && !value" class="noCoverPicture elementInTD">
              <i class="image outline icon" style="margin: 0"></i>
            </div>
            <div v-else-if="field === 'activatedAt' && value" class="noCoverPicture elementInTD" style="font-size: 14px">
              {{ getFormattedActivationDate(value) }}
            </div>
            <div v-else-if="field === 'activatedAt' && !value" class="noCoverPicture elementInTD" style="font-size: 14px">
              Sin fecha
            </div>
            <div v-else-if="field === 'coverPictureURL' && value" class="elementInTD">
              <img :src="value" alt="" class="coverImage" @click="openCoverPicModal(value)">
            </div>
            <div v-else-if="field === 'files'">
              <span style="display:flex; justify-content: center" v-html="formatFilesInfo(value)"></span>
            </div>
            <div v-else-if="field === 'edit'" class="elementInTD">
              <i class="pen icon editPen" @click="openEditModal(bulletin)"></i>
            </div>
            <div v-else-if="field === 'active'" class="ui toggle checkbox" style="width: 100%; display: flex; justify-content: center;">
              <input type="checkbox" :v-model="bulletin.values.active" :checked="bulletin.values.active" @change="changeActivation(bulletin)">
              <label style="height: 0px; width:0px; margin-top: 7px"></label>
            </div>
            <div v-else-if="field === 'link'  && value" class="bulletinLink elementInTD" style="text-decoration: none">
              <a :href="value" target="_blank"><i class="linkify icon"></i></a>
            </div>
            <div v-else-if="field === 'likes'" class="elementInTD" style="text-decoration: none">
              {{value}}
            </div>
            <div v-else-if="field === 'Description'" class="DescriptionField">
              {{value}}
            </div>
            <div v-else>
              {{ value }}
            </div>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10" class="text-center">
              {{ $t("errors.board.noAnnouncements") }}
            </td>
          </tr>
        </tbody>

        <tfoot v-if="bulletinsExist" style="color: #808080;">
          <tr>
            <th colspan="10" style="border: none">
              <div class="ui right floated pagination menu">
                <a class="icon item" style="color: #808080;" @click="prevPageOfBulletin">
                  <i class="left chevron icon"></i>
                </a>
                <a
                  :class="classActivationStatusPage(numPagOfBulletins)"
                  class="item" style="color: #808080"
                  v-for="numPagOfBulletins in numberOfPagesOfBulletinsInSection"
                  :key="numPagOfBulletins"
                  @click="changePageOfBulletin(numPagOfBulletins)"
                  >{{numPagOfBulletins}}</a>
                <a class="icon item" @click="nextPageOfBulletin">
                  <i class="right chevron icon" style="color: #808080;"></i>
                </a>
              </div>
            </th>
        </tr>
      </tfoot>
      </table>
    </div>
    <BulletinModal
      :show="isEditingBulletin"
      :edit_data="modalData"
      :isFullOfImportant="isFullOfImportantBulletins"
      @close="closeEditModal"
    />
    <CoverPicOfBulletinModal
      :show="isShowingCoverPic"
      :edit_data="coverPicModalData"
      @close="closeCoverPicModal"
    />
  </div>
</template>

<script>

// import { SuiModal, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
import HeaderResidential from '@/components/ui/HeaderResidential'
import BulletinModal from '@/components/modals/BulletinModal'
import CoverPicOfBulletinModal from '@/components/modals/CoverPicOfBulletinModal'
import Button from '@/components/form/Button'
import { mapActions, mapGetters } from 'vuex'
import { changeImportance as updateImportance, changeActivationStatus } from '@/api/bulletinBoard'
// import { SuiCheckbox } from 'vue-fomantic-ui'

export default {
  components: {
    HeaderResidential,
    BulletinModal,
    Button,
    CoverPicOfBulletinModal
    // SuiCheckbox
  },
  // created () {
  //   if (this.items) {
  //     this.items = []
  //     // console.log('there are items')
  //   }
  // },
  beforeMount () {
    this.loading = true
    this.setLoading(true)
    this.loadBulletins(this.residential.residentialId)
      .then((r) => {
        // this.loading = false
        // console.log('bulletins loaded')
      }).catch((error) => {
        // this.loading = false
        console.log('error cargando anuncios', error)
      }).finally(() => {
        this.setLoading(false)
        this.loading = false
      })
  },
  // updated () {
  //   console.log('current page of bulletin is ', this.currentPageOfBulletins)
  // },
  data () {
    return {
      loading: false,
      isEditing: false,
      isShowingCoverPic: false,
      numOfImportantBulleting: 3,
      boardHeaders: [
        this.$t('board.headers.title'),
        this.$t('board.headers.cover_image'),
        this.$t('board.headers.description'),
        this.$t('board.headers.link'),
        this.$t('board.headers.files'),
        this.$t('board.headers.reactions'),
        this.$t('board.headers.publication'),
        this.$t('board.headers.edit'),
        this.$t('board.headers.active'),
        this.$t('board.headers.highlighted')
      ],
      modalData: {},
      coverPicModalData: null,
      isActivesSection: true,
      maxBulletinsPerPageOfBulletins: 10,
      currentPageOfBulletins: 1
    }
  },
  computed: {

    numberOfBulletinsInSection () {
      const section = this.isActivesSection
      return this.allBulletins.filter(bulletin => bulletin.active === section).length
    },

    numberOfPagesOfBulletinsInSection () {
      if (this.numberOfBulletinsInSection <= this.maxBulletinsPerPageOfBulletins) {
        return 1
      }
      return Math.ceil(this.numberOfBulletinsInSection / this.maxBulletinsPerPageOfBulletins)
    },

    bulletinsExist () {
      return this.visualBulletin.length !== 0
    },

    residential () {
      return this.$store.getters['residential/activeResidential']
    },

    classObjectCreateBulletin () {
      return {
        /* 'margin-top': '25px',
        'margin-bottom': '20px', */
        height: '29px',
        with: '100px',
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center'
        /* padding: '7px 2.4rem !important' */
      }
    },

    classObjectSection () {
      return {
        height: '29px !important',
        width: '100% !important',
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center'
      }
    },

    isEditingBulletin () {
      return this.isEditing
    },

    ...mapGetters('bulletinBoard', [
      'allBulletins',
      'bulletinById'
    ]),

    visualBulletin () {
      const section = this.isActivesSection
      let bulletinsToShow = this.allBulletins.map(bulletin => {
        const { id, important, title, coverPictureURL, Description, files, activatedAt, active, link, expiresAt, createdAt, likes } = bulletin
        return { id, values: { title, coverPictureURL, Description, link, files, likes, activatedAt, active, important, expiresAt, createdAt } }
      })

      bulletinsToShow = bulletinsToShow
        .filter(bulletin => bulletin.values.active === section)

      // getting 2 arrays, important and the others
      const importantBulletins = []; const nonImportantBulletins = []

      bulletinsToShow.forEach(bulletin => {
        if (bulletin.values.important) {
          importantBulletins.push(bulletin)
        } else {
          nonImportantBulletins.push(bulletin)
        }
      })

      // // Sorting bulletin arrays by importance
      // importantBulletins.sort(function (bulletinA, bulletinB) {
      //   return (bulletinA.values.important === bulletinB.values.important) ? 0 : bulletinA.values.important ? -1 : 1
      // })

      // Sorting bulletins visually by date
      importantBulletins.sort(function (bulletinA, bulletinB) {
        const dateA = new Date(bulletinA.values.activatedAt)
        const dateB = new Date(bulletinB.values.activatedAt)
        // console.log(dateA, dateB)
        return dateB - dateA
      })

      nonImportantBulletins.sort(function (bulletinA, bulletinB) {
        const dateA = new Date(bulletinA.values.activatedAt)
        const dateB = new Date(bulletinB.values.activatedAt)
        // console.log(dateA, dateB)
        return dateB - dateA
      })

      importantBulletins.push(...nonImportantBulletins)
      bulletinsToShow = importantBulletins

      const lowerIdx = this.maxBulletinsPerPageOfBulletins * (this.currentPageOfBulletins - 1)
      const upperIdx = this.currentPageOfBulletins < this.numberOfPagesOfBulletinsInSection ? lowerIdx + this.maxBulletinsPerPageOfBulletins : this.numberOfBulletinsInSection

      return bulletinsToShow.slice(lowerIdx, upperIdx)
    },

    currentNumberOfImportantBulletins () {
      return this.allBulletins.filter(bulletin => bulletin.important).length
    },

    isFullOfImportantBulletins () {
      return !(this.currentNumberOfImportantBulletins < this.numOfImportantBulleting)
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),

    classActivationStatusPage (numPagOfBulletins) {
      return {
        activePage: numPagOfBulletins === this.currentPageOfBulletins
      }
    },

    prevPageOfBulletin () {
      if (this.currentPageOfBulletins !== 1) {
        this.currentPageOfBulletins -= 1
      }
    },

    nextPageOfBulletin () {
      if (this.currentPageOfBulletins !== this.numberOfPagesOfBulletinsInSection) {
        this.currentPageOfBulletins += 1
      }
    },

    changePageOfBulletin (numPagOfBulletins) {
      this.currentPageOfBulletins = numPagOfBulletins
      // console.log('Current page of bulletin is ', this.currentPageOfBulletins)
    },

    openEditModal (bulletin) {
      this.modalData = bulletin
      this.isEditing = true
    },

    openCoverPicModal (pic) {
      this.coverPicModalData = pic
      this.isShowingCoverPic = true
    },

    closeCoverPicModal () {
      this.isShowingCoverPic = false
      this.coverPicModalData = null
    },

    closeEditModal () {
      this.isEditing = false
      this.modalData = {}
    },

    ...mapActions('bulletinBoard', [
      'loadBulletins',
      'update_Bulletin'
    ]),

    classObjectIsImportant (isImportant) {
      return {
        importantThumbtack: isImportant,
        nonImportantThumbtack: !isImportant
      }
    },

    classPageofBulletinSelected () {

    },

    classObjectIsImportantRow (isImportant) {
      return {
        blue: isImportant,
        nonImportantRow: !isImportant
      }
    },

    changeImportance (bulletin, isImportantComing) {
      if (!bulletin.values.active) {
        this.$swal(this.$t('errors.board.announcementMustBeActive'), '', 'error')
        return
      }

      if (this.isFullOfImportantBulletins && !isImportantComing && bulletin.values.active) {
        this.$swal(this.$t('errors.board.maxHighlighted'), '', 'error')
      } else {
        // this.isEditingOnTable = true
        const id = bulletin.id
        const { title, coverPictureURL, Description, link, likes, files, activatedAt, active, important, expiresAt, createdAt } = bulletin.values
        const updatedBulletin = {
          id,
          important: !important,
          title,
          coverPictureURL,
          Description,
          likes,
          link,
          files,
          activatedAt,
          active,
          expiresAt,
          createdAt
        }

        this.setLoading(true)

        updateImportance(id, { isImportant: updatedBulletin.important }).then(() => {
          // this.isEditingOnTable = false
          this.update_Bulletin(updatedBulletin)
          // console.log(updatedBulletin)
          // console.log('importance changed')
        }).catch(error => {
          console.log(error)
          // this.isEditingOnTable = false
          this.$swal(this.$t('errors.somethingHappened'), '', 'error')
        }).finally(() => {
          this.setLoading(false)
        })
      }
    },

    changeActivation (bulletin) {
      const id = bulletin.id
      const { title, coverPictureURL, Description, link, likes, files, activatedAt, active, expiresAt, createdAt } = bulletin.values
      const updatedBulletin = {
        id,
        important: false,
        title,
        coverPictureURL,
        Description,
        likes,
        link,
        files,
        activatedAt,
        active: !active,
        expiresAt,
        createdAt
      }

      this.setLoading(true)

      changeActivationStatus(id, { isActive: updatedBulletin.active }).then(() => {
        // this.isEditingOnTable = false
        this.update_Bulletin(updatedBulletin)
      }).catch(error => {
        console.log(error)
        // this.isEditingOnTable = false
        this.$swal(this.$t('errors.somethingHappened'), '', 'error')
      }).finally(() => {
        this.setLoading(false)
      })
    },

    formatFilesInfo (filesInfo) {
      let numOfFilesToPrint = filesInfo.length
      // const attachmentIcon = '<i class="paperclip icon"></i>'

      return `${Array(3).fill(0).map(() => {
        if (numOfFilesToPrint > 0) {
          numOfFilesToPrint -= 1
          return '<i class="paperclip icon" style="color: #2185d0"></i>'
        } else {
          return '<i class="paperclip icon" style="color: #e1e1e2"></i>'
        }
      }).join('')}`
    },

    getFormattedActivationDate (bulletinActivatedAt) {
      const activationDate = new Date(bulletinActivatedAt)
      let day = activationDate.getDate()
      day = day < 10 ? `0${day}` : `${day}`
      let month = activationDate.getMonth() + 1
      month = month < 10 ? `0${month}` : `${month}`
      return `${day}/${month}/${activationDate.getFullYear()}`
    }
  }
}
</script>

<style scoped>
  .DescriptionField {
    /* background-color: red !important; */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    /* max-width: inherit !important; */
    white-space: nowrap;
  }

    .view-container {
        text-align: left;
        margin-left: 30px;
        margin-top: 17px;
        /*height: 90vh;*/
    }

    .bulletinLink {
      color: #808080 !important;
    }

    .importantThumbtack, .nonImportantThumbtack, .noCoverPicture {
      color: #808080;
      font-size: 20px;
    }

    .importantThumbtack {
      color: rgb(235, 36, 36);
      /* transform: rotate(-45deg); */
      transform: rotate(0deg);
      transition: ease .3s
    }

    /* .importantThumbtack:hover {
      color: #808080;
      transform: rotate(-45deg)
    } */

    .noCoverPicture {
      color: #808080
    }

    .nonImportantThumbtack {
      color: #808080;
      transform: rotate(-45deg);
      transition: ease .3s
    }

    /*.nonImportantThumbtack:hover {
      color: rgb(235, 36, 36);
      transform: rotate(0);
    } */

    .editPen {
      font-size: 15px;
      transition: ease .3s
    }

    .editPen:hover {
      transform: scale(1.2);
    }

    .CoverPicture {
      padding: 0 !important;
    }
    .coverImage {
      height: 30px;
      width: auto
    }
    .elementInTD {
      display:flex;
      justify-content: center;
      align-items: center;
    }

    .nonImportantRow {
      color: #808080;
    }

    .nonImportantRow:hover {
      color: #808080 !important;
    }

    /* .sectionActive {
      background-color: #436f98 !important;
      color: white !important;
      transition: background-color .5s ease !important;
    }
    .sectionActive:hover {
      background-color: #2c97c5 !important;
    } */
    .create_and_changeSection_btns {
      display: flex;
      width: 100%;
      height: 60px;
      justify-content: space-between;
      align-items: center;
      padding-right: 35px;
    }

    .changeSection {
      display: flex;
    }

    .activePage {
  background-color: #436f98 !important;
  color: white !important;
    }
</style>
